export class VanillaAccordion {

  constructor(w, d, accordionSelector) {
    this.w = w;
    this.d = d;
    this.accordion = d.querySelector(accordionSelector);
    this.serviceItems = d.querySelectorAll(`${accordionSelector} .service-item`);
    this.serviceContents = d.querySelectorAll(`${accordionSelector} .service-content`);

    this.#bindEvents();
  }

  #bindEvents() {
    this.serviceItems.forEach(item => item.addEventListener('click', this.#onItemClick, false));
    this.serviceContents.forEach(content => content.addEventListener('transitionend', this.#onContentTransitionEnd, false));
    this.d.querySelector('body').addEventListener('onSectionLeave', this.#onSectionLeave, false);
  }

  #onItemClick = (e) => {
    const item = e.target.closest('.service-item');

    if (item.classList.contains('closed')) {
      this.open(item);
    } else {
      this.close(item);
    }
  }

  #onContentTransitionEnd = (e) => {
    const item = e.target.closest('.service-item');
    const info = this.getInfo(item);

    if (info.height === 0) {
      item.classList.add('closed');
      item.classList.remove('open');
    } else {
      info.content.style.height = 'auto';
    }

    this.w.dispatchEvent(new Event('resize'));
  }

  #onSectionLeave = (e) => {
    if (this.d.getElementById(e.detail.id).contains(this.accordion)) {
      this.closeAll();
    }
  }

  open(item) {
    const info = this.getInfo(item);

    item.classList.add('open');
    item.classList.remove('closed');

    this.w.requestAnimationFrame(() => {
      info.content.style.height = info.height + 'px';
      [...item.parentNode.children]
        .filter(child => child !== item && child.classList.contains('open'))
        .forEach(child => this.close(child));
    });
  }

  close(item) {
    const info = this.getInfo(item);

    info.content.style.height = info.height + 'px';
    this.w.requestAnimationFrame(() => info.content.removeAttribute('style'));
  }

  closeAll() {
    this.serviceItems.forEach(item => { if (!item.classList.contains('closed')) this.close(item); });
  }

  getInfo(item) {
    const content = item.querySelector('.service-content');
    const height  = content.getBoundingClientRect().height;

    return { content, height };
  }
}
