export default function(d, s) {
  const dispatchEvent = (name, data) => {
    d.querySelector('body').dispatchEvent(new CustomEvent(name, { detail: data }))
  }

  d.querySelectorAll('.page-section').forEach((section) => {
    s.create({
      trigger: section,
      start: 'top bottom',
      end: 'bottom top',
      onEnter: (st) => { dispatchEvent('onSectionEnter', { id: st.trigger.getAttribute('id') }) },
      onEnterBack: (st) => { dispatchEvent('onSectionEnter', { id: st.trigger.getAttribute('id') }) },
      onLeave: (st) => { dispatchEvent('onSectionLeave', { id: st.trigger.getAttribute('id') }) },
      onLeaveBack: (st) => { dispatchEvent('onSectionLeave', { id: st.trigger.getAttribute('id') }) },
      invalidateOnRefresh: true,
    });
  });
};
