export default function(d, g) {
  const tagline = d.querySelector('.tagline');
  g.to(tagline, {
    scrollTrigger: {
      trigger: '#home',
      start: 'top top',
      end: 'bottom 30%',
      scrub: true,
      onLeave: (st) => { tagline.classList.add('hide') },
      onEnterBack: (st) => { tagline.classList.remove('hide') },
      invalidateOnRefresh: true,
    },
    scale: 2,
    opacity: 0,
    ease: 'east-in-out'
  });
};
