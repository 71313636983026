export default function(d, s) {
  const header = d.querySelector('.main-header');
  s.create({
    trigger: '#home',
    start: 'bottom 60%',
    end: 'max',
    onEnter: () => header.classList.add('active'),
    onLeaveBack: () => header.classList.remove('active'),
    invalidateOnRefresh: true,
  });
};
