import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

import './modules/menu-smooth-scroll.js';
import triggerPanelsScroll from './modules/trigger-panels-scroll.js';
import toggleHeaderLogo from './modules/toggle-header-logo.js';
import toggleTagline from './modules/toggle-tagline.js';
import { VanillaAccordion } from './modules/vanilla-accordion.js';

triggerPanelsScroll(document, ScrollTrigger);
toggleHeaderLogo(document, ScrollTrigger);
toggleTagline(document, gsap);

new VanillaAccordion(window, document, '#services-accordion');
new VanillaAccordion(window, document, '#aws-accordion');
new VanillaAccordion(window, document, '#directus-accordion');
